.nav-headline {
    background: rgb(2, 0, 36);
    background: linear-gradient(131deg, rgba(2, 0, 36, 1) 45%, rgba(60, 17, 8, 1) 45%);
}

nav {
    position: relative;
    background-color: var(--bs-primary);

    @media (min-width:992px) {
        background-color: transparent;

        &::after {
            top: 0;
            left: 0;
            z-index: -1;
            transform: skewX(331deg);
            position: absolute;
            width: 100%;
            height: 100%;
            content: '';
            background-color: var(--bs-primary);
        }
    }

    .navbar-link {
        color: aliceblue;
        line-height: 35px;
        height: 45px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        min-width: 100px;
        padding: 0 10px;
        font-weight: 500;
        text-transform: uppercase;

        @media (max-width:992px) {
            display: flex;
            width: 100%;
        }
    }

    .navbar-link.active {
        position: relative;
        z-index: 2;

        &::before {
            z-index: -1;
            transform: scale(.9) skewX(331deg);

            @media (min-width:992px) {
                transform: skewX(331deg);
            }

            top: 0;
            left: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            content: '';
            background: linear-gradient(99deg, #CE3329 3%, #CE3329 50%, #662315 100%);
        }

        &:hover {
            &::before {
                background: linear-gradient(90deg, #662315 20%, #CE3329 81%);
            }
        }
    }
}

.btn-cta {
    display: block;
    position: relative;
    z-index: 2;
    line-height: 35px;
    height: 45px;
    color: white;
    font-weight: bold;
    width: 228px;
    text-align: center;
    margin: auto;

    @media (min-width:1024px) {
        float: right;
    }

    &::before {
        z-index: -1;
        top: 0;
        left: 0;
        transform: skewX(331deg);
        position: absolute;
        width: 100%;
        height: 100%;
        content: '';
        background: linear-gradient(90deg, #662315 20%, #CE3329 81%);

    }

    &:hover {
        &::before {
            background: linear-gradient(99deg, #CE3329 3%, #CE3329 50%, #662315 100%);
        }
    }


}

.action-icon {
    justify-content: center;

    @media (max-width:768px) {
        display: flex;
        border: 1px solid #662315;
        height: 58px;

        div:nth-child(2) {
            display: none;
        }
    }
}

.action-text {

    @media (min-width:1024px) {
        font-size: 12px !important;
    }
}

.action-link {
    @media (min-width:1024px) {
        font-size: 14px !important;
    }
}