.section_title {
    font-family: 'Krungthep';
    text-transform: capitalize;

    span {
        color: var(--bs-danger);
    }
}

.section_content {
    text-align: center;
}