.btn-filter {
    background: none;
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    border: none;
    outline: none;
    padding: 10px 25px;
    gap: 20px;

    &::after {
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: skewX(-25deg);
        z-index: -1;
        position: absolute;
        border: 2px solid var(--bs-primary);
    }

    span {
        font-size: 16px;
        font-weight: 500;
        color: var(--bs-primary);
        text-transform: uppercase;
    }
}

.btn-filter.active {
    &::after {
        background: linear-gradient(99deg, #CE3329 3%, #CE3329 54%, #662315 100%)
    }

    img {
        filter: brightness(100);
    }

    span {
        color: white;
    }
}

.service-card {
    border-radius: 0 !important;
    border: 1px solid var(--bs-primary) !important;
    min-height: 310px !important;

    img {
        border-radius: 0 !important;
        object-fit: cover;
    }

    .card-body {
        background-color: #D9D9D9 !important;
    }
}

.text-sm {
    font-size: 12px !important;
}

.is-invalid {
    border: 1px solid #CE3329 !important;
}