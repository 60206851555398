@font-face {
  font-family: "Krungthep";
  src: url("./assets/fonts/Krungthep.ttf");
  font-weight: normal;
  font-style: normal;
}

$primary: #3c1108;
$dark: #0000;
$danger: #ce3329;
$gray: #eeeeee;

@import "bootstrap/scss/bootstrap";

a {
  text-decoration: none;
}

.y-center {
  align-items: center !important;
}

.x-between {
  justify-content: space-between !important;
}

.x-center {
  justify-content: center !important;
}

.form-control {
  background-color: #d9d9d973 !important;
  border: none !important;
  border-radius: 0px !important;
  color: white;

  &::placeholder {
    color: white;
  }
}

.section {
  padding: 40px 20px;

  @media (min-width:992px) {
    padding: 60px 20px;
  }
}

.bg-primary-gradient {
  background: linear-gradient(131deg,
      rgba(206, 51, 41, 1) 0%,
      rgba(80, 22, 13, 1) 18%,
      rgba(60, 17, 8, 1) 18%);
}

.text-justify {
  text-align: justify !important;
}

.blog-banner-image {
  max-height: 450px;
  object-fit: cover;
}

.blog-container {
  @media (min-width:992px) {
    width: 60%;
    margin: auto;
  }

  padding: 40px 20px;
}

.btn-unstyle {
  background: none !important;
  border: none !important;
}

.banner-image {
  max-height: 450px;
  object-fit: cover;
}