.blog-card-content {
    background-color: #D9D9D9;
    text-align: center;
    position: relative;
    border: 2px solid var(--bs-primary);
    border-top: none !important;

    &:hover {
        ::before {
            content: "";
            position: absolute;
            top: -39px;
            left: 0;
            width: 100%;
            height: 127px;
            background: linear-gradient(80deg, #3C1108 0%, #CE3329 100%);
            z-index: 1;
            clip-path: polygon(50% 0%, 0 30%, 100% 30%);
        }
    }

    ::after {
        content: '';
        position: absolute;
        top: -29px;
        left: 0;
        width: 100%;
        height: 100px;
        background-color: #D9D9D9;
        z-index: 1;
        clip-path: polygon(50% 0%, 0 30%, 100% 30%);
    }
}

.blog-title {
    font-weight: bold;
    font-size: 22px;
    text-decoration: underline;
    margin-bottom: 10px;
    padding: 0 20px;
}

.blog-content {
    font-size: 14px;
    max-width: 75%;
    margin: auto;
    padding: 20px;
}

.blog-link {
    font-weight: bold;
    color: var(--bs-danger);
    font-size: 23px;
    padding: 15px 0;
    display: block;
}

.blog-image {
    height: 220px;
    object-fit: cover;
}